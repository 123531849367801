import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const Analyticsandbi = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Metaverse"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">TEQT Analytics & BI services help customers implement use cases for actionable insights. TEQT has a vast coverage on visualization services including -</p>

                        <ul className="circle-list">
                            <li className="mt-2"> <span className="text-black">BI Readiness Assessment – </span>covers analysis of BI requirements, technical architecture, and BI Processes</li>
                            <li className="mt-2"><span className="text-black">BI Application Rationalization –</span>rationalizing the user basis, alternate delivery mechanism.</li>
                            <li className="mt-2"><span className="text-black"> KPI Formation – </span>KPI definition, selection, retiring, KPI tree and flow down.</li>
                            <li className="mt-2"> <span className="text-black">BI Platform Transformation – </span>360-degree assessment of BI program, Business alignment, process maturity, execution capability</li>
                            <li className="mt-2"> <span className="text-black">IoT Visualization –</span>Real time dashboards, Online triggers, and events</li>
                            <li className="mt-2"> <span className="text-black">BI Tools Standardization –</span>selection of appropriate Business Intelligence and reporting tool based on quantified scoring model.</li>
                            <li className="mt-2"> <span className="text-black">CxO Dashboards –</span>KPIs in 6 functional areas – Customer (Satisfaction), Financials, Quality & Audit, Sales & Marketing, Leaning & Growth, Operations</li>
                        </ul>

                       
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query AnalyticsandbiPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

Analyticsandbi.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Analyticsandbi;
